import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import locales from "../../../constants";

const Vortune = ({ pageContext: { slug } }) => {
  const lang = "en";
  const nav = {
    initialSlideIndex: 1,
  };
  return (
    <Layout
      seo={{
        title: "Vortune",
        href: slug,
        lang: "en",
      }}
      header={{
        background:
          "linear-gradient( 45deg, #df7a61, #eb876e, #FE8A6E, #eb876e, #df7a61 )",
        icons: "#FE8A6E",
        navClass: "vortune",
        ogImage: require("../../../assets/img/portfolio/vortune_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/vortune/",
      }}
    >
      <PortfolioHeader name="vortune" height="81" />
      <section className="container-fluid vortune_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Vortune Equity</h1>
              <ul>
                <li>Website</li>
                <li>Lead Generation Campaign</li>
                <li>Advertising banners</li>
              </ul>
            </div>
          </div>

          <div className="col-md-6">
            <div className="inner">
              <p>
                As a part of our regular cooperation with our Client, we
                received the order to create a Landing Page, which had a goal of
                attracting potential investors interested in real estate
                investments. The site was to present an investment offer for the
                purchase of a floor for renting commercial premises in the SQ
                Business Center office building in Wrocław, Poland.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid vortune_section_3">
        <div className="row row_1">
          <div className="col-md-6">
            <p>
              We have prepared a marketing plan with market analysis and
              forecasts for the upcoming months. We've also created a full
              package of marketing banners for website promotion in online
              advertising networks.
            </p>
          </div>
          <div className="col-md-6 text-right">
            <div className="scroll_wrapper">
              <span />
              <div className="img_wrapper">
                <img
                  src={require("../../../assets/img/portfolio/vortune_entire_page.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row no-gutters row_2">
          <div className="col-md-5">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/vortune_main_1.png")}
              alt=""
            />
          </div>
        </div>
        <div className="row no-gutters row_3">
          <div className="col-md-7 offset-md-5 text-right">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/vortune_main_2.png")}
              alt=""
            />
          </div>
        </div>
        <div className="row no-gutters align-items-center row_4">
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/vortune_main_3.png")}
              alt=""
            />
          </div>
          <div className="col-md-6">
            <p>
              On the basis of the website we have coded, we are successively
              conducting a marketing campaign for the Client in social media and
              Google and Facebook Ads advertising network.
            </p>
          </div>
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Vortune;
